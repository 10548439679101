@use "../tools/mixins";

/*
==========================================================================
   #Slider
========================================================================== */
:root {
    --accordion-transition-time: 0.5s;
    --accordion-border-width: 1px;
    --accordion-arrow-width: 59px;
    --accordion-title-padding-v: 15px;
    --accordion-title-padding-h: 19px;
    --accordion-body-padding-v: 20px;
    --accordion-body-padding-h: 20px;
    --accordion-item-margin-v: 18px;

    // Accordion colors
    --color-accordion-border: #9a9a9a;
    --color-accordion-bg: #ffffff;
    --color-accordion-text: #11062f;
    --color-accordion-focus-text: #11062f;
    --color-accordion-focus-bg: #ffffff;
    --color-accordion-arrow: #11062f;
    --color-accordion-content-border-top: #d6dee2;
}

.accordion-item {
    border: var(--accordion-border-width) solid var(--color-accordion-border);
    margin: var(--accordion-item-margin-v) 0;

    &__title-container {
        position: relative;
        display: block;
        background-color: var(--color-accordion-bg);
        text-decoration: none;
        color: var(--color-accordion-text);
        font-weight: bold;
        padding: var(--accordion-title-padding-v)
            var(--accordion-title-padding-h);
        transition: background-color var(--transition-time);

        &:hover,
        &:focus {
            text-decoration: none;
        }

        @include mixins.hover(
            var(--color-accordion-focus-text),
            var(--color-accordion-focus-bg)
        );
    }

    &__arrow {
        display: inline-block;
        width: var(--accordion-arrow-width);
        height: 10px;
        text-align: center;
        background-image: url("../icons/arrow-right-solid.svg");
        background-repeat: no-repeat;
        margin-left: 10px;
    }

    &__body-wrapper {
        padding: var(--accordion-body-padding-v) var(--accordion-body-padding-h);
    }
}
