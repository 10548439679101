@use "../tools/mixins";

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &__avatar {
        margin-right: 21px;
    }

    &__user-type {
        display: block;

        @include mixins.font-size(16px, 24px);
        @include mixins.font(PKFGlobalSans, reg);
        margin-right: 10px;
    }

    &__user-name {
        display: block;

        @include mixins.font-size(14px, 21px);
        @include mixins.font(PKFGlobalSans, light);
    }
}
