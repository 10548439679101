@use "../tools/mixins";

.grid-table {
    --color-table-td-border: #505a69;
    --color-table-td-text: #11062f;
    --color-table-bg: #d6edff;
    --title-text: #ffffff;
    --title-bg: #11062f;

    border: 0;
    border-top: 1px solid var(--color-table-td-border);
    table-layout: unset;

    tr {
        border-bottom: var(--table-border-width) solid
            var(--color-table-td-border);
        position: relative;
        z-index: 0;
    }

    td {
        border: 0;
        padding: 22px 20px;
    }

    .grid-table__cell-md {
        flex-basis: 296px;
        max-width: 296px;
        padding-right: 10px;
        padding-top: 16px;
        padding-bottom: 0;
    }

    &__cell-push-end {
        margin-left: auto;
    }

    .bg-blue {
        background: var(--color-table-bg);
    }

    .star {
        padding-left: 33px;
        padding-right: 10px;
    }

    .fix-width {
        flex: 1 0 auto;

        &.grid-table__cell-push-end {
            display: flex;
            justify-content: flex-end;
        }
    }

    [data-title]:hover::after,
    [data-title]:focus::after {
        opacity: 1;
        transition: all 0.1s ease 0.5s;
        visibility: visible;
    }

    [data-title]::after {
        content: attr(data-title);
        background-color: var(--title-bg);
        color: var(--title-text);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
        font-size: 10px;
        line-height: 16px;

        @include mixins.font(PKFGlobalSans, semibold);
        position: absolute;
        bottom: 0;
        left: 20px;
        white-space: nowrap;
        opacity: 0;
        z-index: 99999;
        visibility: hidden;
        border-radius: 0 10px;
        padding: 5px 10px;
    }

    [data-title] {
        position: relative;
    }
}
