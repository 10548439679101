/*
==========================================================================
   #Page footer
========================================================================== */
// TODO : remove unused styles and make sure colors are using variables

.page-footer {
    border-top: 2px solid #cccccc;
    padding: 50px 0;

    a {
        font-weight: normal;
        text-decoration: none;
    }

    .icon-butterfly {
        position: relative;

        &::before {
            position: absolute;
            left: 100%;
        }
    }
}
