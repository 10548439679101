@use "../tools/mixins";

.color-options {
    display: flex;
    gap: 10px;
    margin: 10px 0;
}

.color-wrapper {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
    transition: border-color 0.3s;
    position: relative;
}

/* Add border to the label when the radio input is checked */
input[type="radio"]:checked + label.color-wrapper {
    border: 5px solid #1f0b0b; /* Adjust the border color as desired */
}

input[type="radio"] + label.color-wrapper::after,
input[type="radio"]:checked + label.color-wrapper::before {
    opacity: 0;
}

#incident-tag-list {
    .badge {
        margin-bottom: 20px;
    }
    .tag-name-edits {
        padding: 0;
        background-color: transparent;
        border: 0;

        @include mixins.font-size(14px, 20px);
        width: auto;

        @include mixins.font(PKFGlobalSans, semibold);
    }

    .color-11062f input {
        color: var(--color-white);
    }

    .btn-tag-edit {
        padding: 0;
        background: url("../icons/pen.svg") no-repeat;
        overflow: hidden;
        text-indent: -9999px;
        background-size: contain;
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
    }
}

.add-tags {
    .select2-selection__rendered {
        display: none !important;
    }
}
