@use "../tools/functions";
@use "../tools/mixins";

/*
==========================================================================
   #Links
========================================================================== */

a {
    position: relative;
    text-decoration: underline;
    color: var(--color-link);
    transition: color var(--transition-time);

    @include mixins.font(PKFGlobalSans, reg);
    @include mixins.font-size(16px, 24px);

    &:focus,
    &:hover {
        text-decoration: underline;
    }

    &:focus {
        outline: var(--focus-border-width) solid var(--color-focus);
        outline-offset: var(--outline-offset);
        z-index: functions.z-index(default);
    }

    @include mixins.hover(var(--color-link-hover));
}
