@use "../tools/mixins";

/*
==========================================================================
   #Navigation
========================================================================== */
// TODO : remove unused styles and update accordingly also make sure colors are using variables
// Navigation colors
:root {
    --color-navbg--level1: #cccccc;
    --color-navbg--level1--hover: #191d1f;
    --color-navbg--level2: #cccccc;
    --color-navbg--level2--hover: #303436;
    --color-navbg--level3: #cccccc;
    --color-navbg--level3--hover: #686f73;
}

.page-navigation-menu {
    @include mixins.breakpoint(not-desktop) {
        display: none;
    }

    ul {
        list-style-type: none;

        a {
            text-decoration: none;
            font-weight: bold;
        }

        &.menu {
            padding: 0;
            margin: 0 0 10px;
            display: table;
            width: 100%;
            background: var(--color-navbg--level1);
        }
    }

    li ul {
        z-index: 99999;
        display: block;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        left: 0;
        padding: 0;
        min-width: 250px;
        background: #ffffff;
        transition: opacity 400ms linear;
    }

    /* Level 1 */

    .level1 {
        display: table-cell;
        list-style: none none;
        position: relative;

        > a {
            text-align: center;
            font-size: 16px;
        }
    }

    // Level 2

    .level2 > ul {
        left: 250px;
        margin-top: -50px;
    }
}
