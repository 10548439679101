@use "../tools/mixins";

:root {
    --sidebar-bg: #11062f;
    --menu-active-bg: #d6edff;
    --menu-icon-active: #11062f;
    --menu-icon-new: #33ad83;
}

.dashboard-sidebar {
    position: fixed;
    top: 0;
    z-index: 7;
    display: flex;
    flex-direction: column;
    width: var(--sidebar-width);
    height: 100%;
    background-color: var(--sidebar-bg);
    padding-top: 20px;
    align-items: center;

    @include mixins.breakpoint(mobile) {
        bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__logo {
        display: inline-flex;
        margin-bottom: 20px;
        text-align: center;
        width: 60px;
        height: 60px;

        img {
            max-width: 100%;
            width: 100%;
            height: auto;
            margin: auto;
        }
    }

    &__menu {
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 0;
        text-align: center;
        list-style: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .menu__item {
        a,
        button {
            display: block;
            position: relative;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 10px;

            @include mixins.breakpoint(mobile) {
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }

        &.active a,
        a:hover,
        button:hover,
        a:focus,
        button:focus {
            background-color: var(--menu-active-bg);
        }

        &.active,
        a:hover,
        button:hover,
        a:focus,
        button:focus {
            path,
            circle {
                fill: var(--menu-icon-active);
            }

            circle ~ path {
                fill: #ffffff;
            }
        }

        a:focus,
        button:focus {
            outline: 0;
        }

        &.icon-question-mark {
            &.active,
            a:hover,
            a:focus {
                path,
                ellipse {
                    fill: var(--color-white);
                }
            }
        }

        .circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            right: 30px;
            top: 32px;
            background: var(--menu-icon-new);
        }

        button {
            background: none;
            border: none;
            width: 100%;
        }
    }
}
