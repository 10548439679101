@use "../tools/functions";
@use "../tools/mixins";

/*
==========================================================================
   #Side Menu
   ***Note: Add class "menu-side" to module
========================================================================== */
:root {
    --side-menu-padding-v: 13px;
    --side-menu-padding-h: 20px;
    // indent padding
    --side-menu-padding-indent: 20px;
    --side-menu-text: #000000;
    --side-menu-text-l1: #ffffff;
    --side-menu-bg-active-default: #004890;
    --side-menu-bg-hover: #004890;
    --side-menu-text-hover: #ffffff;
    --side-menu-bg-l1: #002b56;
    --side-menu-bg-l2: #ffffff;
    --side-menu-bg-l3: #ebebeb;
    --side-menu-bg-l4: #dddddd;
    --side-menu-bg-l5: #cdcdcd;
    --side-menu-border-color-default: #cccccc;
    --side-menu-border: 1px solid var(--side-menu-border-color-default);
}

.menu-side {
    padding-left: 0;
    color: var(--side-menu-text);
    border-left: var(--side-menu-border);
    border-right: var(--side-menu-border);
    border-top: var(--side-menu-border);

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        ul {
            display: none;
            width: 100%;
        }
    }

    .active > ul {
        display: block;
    }

    .menu > li,
    .level1.active > .icon-indicator {
        display: none;
    }

    li {
        display: flex;
        flex-wrap: wrap;

        a {
            display: block;
            border-bottom: var(--side-menu-border);
            flex: 1 0 0;
            padding: var(--side-menu-padding-v) var(--side-menu-padding-h);
            text-decoration: none;

            @include mixins.font-size(18px, 24px);
        }
    }

    .level1 {
        &.current.active > a {
            text-decoration: none;
        }

        &.active {
            display: block;
        }
    }

    .level2 {
        > a {
            background: var(--side-menu-bg-l2);
        }

        .icon-indicator {
            background-color: var(--side-menu-bg-l2);

            &.open {
                background-color: var(--side-menu-bg-l3);
            }
        }
    }

    .level3 {
        > a {
            padding-left: calc(
                var(--side-menu-padding-indent) + var(
                    --side-menu-padding-h
                )
            );
            background: var(--side-menu-bg-l3);
            border-bottom: var(--side-menu-border);
        }

        .icon-indicator {
            background-color: var(--side-menu-bg-l3);

            &.open {
                background-color: var(--side-menu-bg-l4);
            }
        }
    }

    .level4 {
        > a {
            padding-left: calc(
                var(--side-menu-padding-indent) * 2 + var(--side-menu-padding-h)
            );
            background: var(--side-menu-bg-l4);
            border-bottom: var(--side-menu-border);
        }

        .icon-indicator {
            background-color: var(--side-menu-bg-l4);

            &.open {
                background-color: var(--side-menu-bg-l5);
            }
        }
    }

    .level5 {
        > a {
            padding-left: calc(
                var(--side-menu-padding-indent) * 3 + var(
                    --side-menu-padding-h
                )
            );
            background: var(--side-menu-bg-l5);
            border-bottom: var(--side-menu-border);
        }
    }

    .level2,
    .level3,
    .level4 {
        &.current.active > a {
            @include mixins.font(hind, medium);

            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 5px;
                height: 100%;
                background-color: var(--side-menu-bg-active-default);
            }
        }

        a,
        .icon-indicator {
            position: relative;

            @include mixins.hover-prop {
                background-color: var(--side-menu-bg-hover);
                color: var(--side-menu-text-hover);
            }
        }
    }

    .icon-indicator {
        display: block;
        position: relative;
        width: 52px;
        right: 0;
        top: 0;
        z-index: functions.z-index(icon-indicator);
        padding: 0;
        cursor: pointer;
        border-left: var(--side-menu-border);
        border-bottom: var(--side-menu-border);
        height: auto;
        background-image: none;

        &.open {
            &::before {
                transform: translateY(-50%) rotate(-90deg);
            }
        }

        &::before {
            @extend %icon, %icon-arrow-right !optional;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            transition: transform var(--transition-time);

            @include mixins.font-size(13px, 13px);
            width: 27px;
            // safari focus state fix
            margin: auto;
            // safari focus state fix
        }

        &:focus {
            outline: var(--focus-border-width) solid var(--color-focus);
            outline-offset: var(--outline-offset);
            z-index: functions.z-index(default);
        }
    }
}
