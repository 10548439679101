@use "../tools/functions";
@use "../tools/mixins";

/*
==========================================================================
   #Page header
========================================================================== */
:root {
    --header-padding-v: 20px;
    --header-padding-h: 20px;

    @include mixins.breakpoint(tablet) {
        --header-padding-h: 32px;
    }

    @include mixins.breakpoint(mobile) {
        --header-padding-h: 16px;
    }
}

$color-header-bg-default: #003594;

.page-head {
    padding: var(--header-padding-v) var(--header-padding-h);
    background-color: $color-header-bg-default;
    font-family: PKFGlobalSans;

    .logo {
        height: 52px;
        width: auto;
    }
}
