@use "mixins";

/*
==========================================================================
   #Utilities
========================================================================== */

// #mobile only classes
@include mixins.breakpoint(desktop) {
    .desktopHide,
    .mobileOnly {
        display: none !important;
    }
}

@include mixins.breakpoint(tablet) {
    .tabletHide,
    .mobileOnly {
        display: none !important;
    }
}

@include mixins.breakpoint(mobile) {
    .mobileHide {
        display: none !important;
    }
}
