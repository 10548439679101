@use "../tools/mixins";
.advanced-search {
    &__heading {
        margin-bottom: 20px;
        margin-top: 0;
    }

    input[type="date"] {
        margin-right: 38px;
    }
}

.advanced-search-container {
    width: 100%;
    margin-bottom: 40px;
    margin-top: 20px;
    clear: both;
    float: left;
    padding: 25px 30px 20px;

    .flex-dk {
        @include mixins.breakpoint(tablet) {
           display: flex;
           flex-wrap: wrap;
        }
    }

    .quarter-width {
        @include mixins.breakpoint(tablet) {
            width: calc(50% - 10px);
        }
    }
}
