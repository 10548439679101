@use "../tools/mixins";

.case-page {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);

    .status-form {
        text-align: left;
        display: flex;
        align-items: center;

        select{
            width: auto;
            margin-left: 10px;
        }
    }

    &__top {
        flex-shrink: 0;
    }

    &__main {
        overflow-y: auto;
        min-height: 116px;

        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #9a9a9a;
            border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #505a69;
            border-radius: 10px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555555;
        }

        &.card {
            padding-right: 74px;
        }

        .h4 {
            margin-top: 0;
            margin-bottom: 35px;
        }
    }

    &__details {
        display: block;
        justify-content: space-between;
        margin-bottom: 21px;

        .label {
            margin-right: 10px;
        }
    }

    &__details-actions {
        margin-bottom: 25px;
        text-align: right;

        .action-btns {
            flex: 1 0 auto;
            margin-bottom: 10px;
            display: flex;
            gap: 10px;

            @include mixins.breakpoint(mobile) {
                flex-direction: column;
            }
        }
    }

    &__details-school {
        margin-right: 49px;
    }

    &__contact-info {
        margin-right: 30px;
    }

    .badge {
        margin-bottom: 5px;
    }

    .note-details {
        @include mixins.font-size(14px, 21px);
    }

    .note-content {
        margin-bottom: 30px;
        padding-left: 20px;
    }

    .icon-edit {
        margin-left: 21px;
    }

    .columns {
        @include mixins.breakpoint(not-mobile) {
            display: grid;
            gap: 0 20px;
        }

        @include mixins.breakpoint(desktop) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mixins.breakpoint(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .view-details-update__fields {
        .report-details {
            .details-list {
                @include mixins.breakpoint(not-mobile) {
                display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px 20px;
                    list-style: none;
                    padding-left: 0;
                }
            }
        }
    }

    .disclosure-background {
        .form-group {
            margin-bottom: 40px;

            .h6 {
                margin-bottom: 5px;
            }

            p {
                margin-top: 5px;
            }
        }
    }

    // case status and popup 
    .case-modal,.outcome-modal {
        display: none;
        position: fixed;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        background:var(--color-white);
        padding: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        z-index: 1000;
        min-width: 350px;
    }

    .outcome-modal {
        p {
            text-align: left;
        }
        
        .outcome-dropdown{
            margin-bottom: 20px;
        }
    }

    .case-modal-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 999;
    }
}

// .view-details-update__fields {
//     display: flex;
//     flex-wrap: wrap;
// }


// .view-details-update__fields {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 20px; /* Space between columns */
// }

// .view-details-update__fields .left-column {
//     flex: 0 0 80%; /* Fixed width for the left column */
//     max-width: 80%;
//     background: #f9f9f9;
//     padding: 20px;
//     border: 1px solid #ddd;
//     border-radius: 5px;
// }

// .view-details-update__fields .right-column {
//     flex: 0 0 20%; /* Fixed width for the right column */
//     max-width: 20%;
//     background: #f9f9f9;
//     padding: 15px;
//     border: 1px solid #ddd;
//     border-radius: 5px;
// }

// /* Additional styling for headers */
// .view-details-update__fields h2 {
//     font-size: 1.5em;
//     margin-bottom: 15px;
//     color: #333;
// }

// /* Styling for form groups in both columns */
// .view-details-update__fields .form-group h4 {
//     font-size: 1.1em;
//     font-weight: bold;
//     margin-bottom: 5px;
//     color: #555;
// }

// .view-details-update__fields .form-group p {
//     font-size: 0.9em;
//     color: #333;
// }

// /* Editable Fields */
// .view-details-update__fields .editable-fields .choice-wrapper {
//     margin-bottom: 15px;
// }

// .view-details-update__fields .editable-fields .choice-wrapper .label {
//     font-weight: bold;
//     font-size: 1.1em;
//     margin-bottom: 10px;
//     display: block;
//     color: #555;
// }

// .view-details-update__fields .editable-fields .checkbox {
//     margin: 5px 0;
// }

// .view-details-update__fields .editable-fields .checkbox label {
//     margin-left: 5px;
//     font-size: 0.9em;
//     color: #333;
// }
