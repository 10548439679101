@use "../tools/mixins";

.internal-menu {
    --item-bg: #11062f;
    --item-bg-hover: #d6edff;
    --item-bg-disabled: #9a9a9a;
    --item-color: #ffffff;
    --item-color-hover: #043394;
    --item-color-disabled: #ffffff;
    margin-bottom: 30px;
    position: relative;

    ul {
        list-style: none;
        display: flex;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
        justify-content: space-between;

        @include mixins.breakpoint(tablet) {
            flex-wrap: wrap;
        }

        @include mixins.breakpoint(mobile) {
            display: none;
            position: absolute;
            top: calc(100% + 6px);
            left: 0;
            right: 0;
            background: #ffffff;
            border: 1px solid #dddddd;
            border-top: none;
            z-index: 10;
            box-shadow: 0 8px 14px 8px rgba(0, 0, 0, 0.10), 0 4px 8px 4px rgba(77, 77, 77, 0.15);

            &.is-active {
                display: block;
            }
        }
    }

    a {
        display: block;
        text-decoration: none;
        background: var(--item-bg);
        color: var(--item-color);
        padding: 20px;
        text-transform: uppercase;

        @include mixins.font-size(16px, 24px);
        @include mixins.font(PKFGlobalSans, semibold);

        @include mixins.hover-prop {
            background: var(--item-bg-hover);
            color: var(--item-color-hover);
        }
    }

    &__item {
        text-align: center;
        flex: 1 0 auto;

        @include mixins.breakpoint(mobile) {
            border-bottom: 1px solid #dddddd;
        }

        &:last-child {
            @include mixins.breakpoint(mobile) {
                border-bottom: none;
            }
        }

        &.active {
            a {
                background: var(--item-bg-hover);
                color: var(--item-color-hover);
            }
        }

        &.disabled {
            a {
                background: var(--item-bg-disabled);
                color: var(--item-color-disabled);
                pointer-events: none;
            }
        }

        a {
            @include mixins.breakpoint(mobile) {
                padding: 12px;
                display: flex;
                align-items: center;
            }
        }

        .icon {
            display: inline-block;
            vertical-align: middle;
            width: 24px;
            height: 23px;
            background-repeat: no-repeat;
            margin-right: 11px;
        }

        .icon-note {
            background-image: url("../icons/note.svg");
        }

        .icon-add-note {
            background-image: url("../icons/add-note.svg");
        }

        .icon-email {
            background-image: url("../icons/email.svg");
        }

        .icon-attach-file {
            background-image: url("../icons/attach-file.svg");
        }

        .icon-status {
            background-image: url("../icons/status.svg");
        }

        &.active a,
        a:hover,
        a:focus {
            background: var(--item-bg-hover);
            color: var(--item-color-hover);

            .icon-note {
                background-image: url("../icons/note-blue.svg");
            }

            .icon-add-note {
                background-image: url("../icons/add-note-blue.svg");
            }

            .icon-email {
                background-image: url("../icons/email-blue.svg");
            }

            .icon-attach-file {
                background-image: url("../icons/attach-file-blue.svg");
            }

            .icon-status {
                background-image: url("../icons/status-blue.svg");
            }
        }
    }

    &__toggle {
        display: none;
        width: 100%;
        padding: 12px;
        background-color: #ffffff;
        border: 1px solid var(--color-3);
        border-radius: 0 10px;
        text-align: left;
        align-items: center;
        justify-content: space-between;
        background-image: url("../icons/dropdown-arrow-2.svg"),
        url("../icons/dropdown-arrow-box.svg");
        background-position: right 15px center, right -1px center;
        background-repeat: no-repeat;

        @include mixins.breakpoint(mobile) {
            display: flex;
        }

        .icon-chevron {
            transition: transform 0.3s ease;
        }

        &[aria-expanded="true"] {
            .icon-chevron {
                transform: rotate(180deg);
            }
        }
    }
}

