@use "../tools/mixins";

.cases-page {
    .create-case-form-btn {
        float: left;
        margin-bottom: 22px;
    }

    .form-columns {
        &__column {
            @include mixins.breakpoint(desktop) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 0 20px;
            }
        }
    }
}
