.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    list-style: none;
    padding-left: 0;

    a {
        padding: 10px;
        text-decoration: none;
    }

    .page-item.active {
        a {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}