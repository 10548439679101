@use "../tools/mixins";

.badge {
    --color-border: #043394;
    --color-rescheduled-bg: #9a9a9a;
    --color-postponed-bg: #9a9a9a;
    --color-urgent-bg: #eb615d;
    --color-visited-bg: #33ad83;
    --color-booked-bg: #33ad83;
    --color-awaiting-reply-bg: #f8b00a;
    --color-follow-up-bg: #f8b00a;
    --color-blue-bg: #2e60c7;
    --color-green-bg: #33ad83;
    --color-yellow-bg: #f8b00a;
    --color-medium-grey-bg: #9a9a9a;
    --color-red-bg: #eb615d;
    --color-black-bg: #11062f;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 2px solid var(--color-border);
    padding: 6px 14px;

    @include mixins.font-size(14px, 21px);

    .current-tags & {
        padding-bottom: 9px;
        padding-top: 9px;

        @include mixins.font-size(14px, 20px);
    }

    &--rescheduled {
        background: var(--color-rescheduled-bg);
        border-color: var(--color-rescheduled-bg);
    }
    &--urgent {
        background: var(--color-urgent-bg);
        border-color: var(--color-urgent-bg);
    }
    &--visited {
        background: var(--color-visited-bg);
        border-color: var(--color-visited-bg);
    }
    &--booked {
        background: var(--color-booked-bg);
        border-color: var(--color-booked-bg);
    }
    &--awaiting-reply {
        background: var(--color-awaiting-reply-bg);
        border-color: var(--color-awaiting-reply-bg);
    }
    &--postponed {
        background: var(--color-postponed-bg);
        border-color: var(--color-postponed-bg);
    }
    &--follow-up {
        background: var(--color-follow-up-bg);
        border-color: var(--color-follow-up-bg);
    }

    // Specific color badges based on COLOUR_CHOICES
    &--blue {
        background-color: var(--color-blue-bg);
        border-color: var(--color-blue-bg);
        color: var(--color-white);
    }
    &--green {
        background-color: var(--color-green-bg);
        border-color: var(--color-green-bg);
    }
    &--yellow {
        background-color: var(--color-yellow-bg);
        border-color: var(--color-yellow-bg);
    }
    &--medium-grey {
        background-color: var(--color-medium-grey-bg);
        border-color: var(--color-medium-grey-bg);
    }
    &--red {
        background-color: var(--color-red-bg);
        border-color: var(--color-red-bg);
    }
    &--black {
        background-color: var(--color-black-bg);
        border-color: var(--color-black-bg);
        color: var(--color-white);
    }

    button.remove {
        padding: 0;
        background-image: url("../icons/close-white.svg");
        background-repeat: no-repeat;
        background-color: var(--color-border);
        background-size: 7.5px;
        background-position: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-left: 11.5px;
    }
}
