.login-container {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;

    h2 {
        text-align: center;
    }

    button[type="submit"] {
        display: flex;
        margin-left: auto;
        margin-right: auto;
    }

    .logo {
        width: 300px;
    }
}
