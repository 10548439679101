.star-icon {
    --color-star-hover: #505a69;
    --color-star-starred: #f8b00a;
    display: inline-block;
    position: relative;
    background-size: contain;
    width: 17px;
    height: 17px;
    background-color: transparent;
    padding: 0;
    vertical-align: middle;
    background-repeat: no-repeat;

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background: var(--color-1);
        transform: scale(0);
        transform-origin: center;
        transition: transform 0.2s;
        position: relative;
        top: -31px;
        left: -6px;
        z-index: -1;
        pointer-events: none;
    }

    &:hover {
        &::after {
            transform: scale(1);
        }
    }
}

.starred {
    svg {
        fill: var(--color-star-starred);
        path {
            stroke: var(--color-star-starred);
        }
    }
}

.not-starred {
    &:hover svg {
        fill: var(--color-star-hover);
    }
}
