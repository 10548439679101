.icon-edit {
    --color-icon-hover: #505a69;
    display: inline-block;
    position: relative;
    background-size: contain;
    width: 24px;
    height: 24px;
    background-color: transparent;
    padding: 0;
    vertical-align: middle;
    background-repeat: no-repeat;

    &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background: var(--color-1);
        transform: scale(0);
        transform-origin: center;
        transition: transform 0.2s;
        position: relative;
        top: -34px;
        left: -4px;
        z-index: -1;
        pointer-events: none;
    }

    &:hover,
    &:focus {
        &::after {
            transform: scale(1);
        }

        svg {
            fill: var(--color-icon-hover);
        }
    }
}
