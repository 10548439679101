@use "../tools/mixins";
.settings-search {
    margin-bottom: 36px;

    .btn-tertiary {
        @include mixins.breakpoint(desktop) {
            margin-right: 30px;
        }
    }

    .select-floating-label {
        @include mixins.breakpoint(desktop) {
            margin-right: 27px;
        }
    }

    .search-field {
        @include mixins.breakpoint(desktop) {
            margin-right: 10px;
        }

        input {
            @include mixins.breakpoint(desktop) {
                width: 342px;
            }
        }
    }

    &--school {
        select {
            min-width: auto;

            @include mixins.breakpoint(desktop) {
                max-width: 180px;
            }

            @include mixins.breakpoint(not-desktop) {
                width: 100%;
            }
        }
    }

    .large-form-field.horizontal  .field-wrapper {
        @include mixins.breakpoint(not-desktop) {
            display: block;
        }
    }

    .search-form  {
        @include mixins.breakpoint(not-desktop) {
            margin-top: 24px;
        }
    }
}
