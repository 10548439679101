@use "../tools/mixins";

:root {
    --tooltip-text: #ffffff;
    --tooltip-bg: #11062f;
}
.tooltip {
    position: relative;

    &__text {
        display: block;
        position: absolute;
        visibility: hidden;
        background-color: var(--tooltip-bg);
        color: var(--tooltip-text);
        padding: 5px 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
        font-size: 10px;
        line-height: 16px;

        @include mixins.font(PKFGlobalSans, semibold);
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        transition: opacity 0.6s;
        opacity: 0;
        min-width: 70px;
        text-align: center;
        width: max-content;
        max-width: 200px;
        border-radius: 0 10px;

        &--right {
            top: 50%;
            transform: translate(0, -50%);
            left: 100%;
        }

        &--left {
            top: 50%;
            transform: translate(0, -50%);
            right: calc(100% + 5px);
            left: auto;
        }

        &--top {
            top: auto;
            bottom: calc(100% + 5px);
            transform: translate(-50%, 0);
        }

        &--bottom {
            top: calc(100% + 5px);
            left: 0;
            transform: translate(-50%, 0);
        }
    }

    &:hover,
    &:focus {
        .tooltip__text {
            visibility: visible;
            opacity: 1;
        }
    }
}
