@use "../tools/mixins";

/*
==========================================================================
   #Messages
========================================================================== */
:root {
    --system-alert-border-width: 3px;
}

/* System Messages */

.messages {
    position: fixed;
    width: 100%;
    list-style: none;
    color: var(--color-system-alert-msg-text);

    @include mixins.font-size(16px, 24px);
    margin: 0;
    margin-bottom: 0;
    padding-left: 0;
    z-index: 1;

    .has-sidebar & {
        padding-left: var(--sidebar-width);
    }

    .info,
    .success {
        background-color: var(--color-system-alert-success-bg);
    }

    .warning,
    .error {
        background-color: var(--color-system-alert-error-bg);
    }

    li {
        align-items: center;
        padding: 15px;
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .message-close-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        background-image: url("../icons/close-white.svg");
        width: 24px;
        height: 24px;
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 0;
        display: block;
        padding: 0;

        &:hover {
            filter: brightness(0) saturate(100%) invert(5%) sepia(77%) saturate(6949%) hue-rotate(218deg) brightness(55%) contrast(113%);
        }
    }
}
