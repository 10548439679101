@use "../tools/mixins";
.case-list {
    &__case-number {
        width: 95px;
    }
    .case-list__tags {
        position: relative;
        display: flex;
        min-width: 44%;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 0;

        .badge:not(:last-of-type) {
            margin-right: 6px;
        }

        .more {
            display: none;
            position: absolute;
            right: 0;
            top: 22px;
            height: 37px;
            width: 132px;
            background: linear-gradient(
                270deg, #ffffff 27.5%, rgba(255, 255, 255, 0) 98.5%
            );
            text-align: right;
            padding-right: 11px;

            &.show {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
    &__row-column {
        text-align: left;
    }
    &__row-header {
        text-align: left;
    }
}
.bg-blue {
    .case-list__tags .more {
        background: linear-gradient(
            270deg, #d6edff 27.5%, rgba(255, 255, 255, 0) 98.5%
        );
    }
}

.document-heading {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;

    @include mixins.breakpoint(mobile) {
      flex-direction: column;
    }
}
