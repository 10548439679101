@use "../tools/mixins";

/*
==========================================================================
   #Table
========================================================================== */
:root {
    --table-border-width: 1px;
    --table-padding-v: 10px;
    --table-padding-h: 20px;

    // Table colors
    --color-table-border: #b6b6b6;
    --color-table-tr-odd-bg: #eeeeee;
    --color-table-tr-even-bg: #ffffff;
    --color-table-th-border: #b6b6b6;
    --color-table-th-bg: transparent;
    --color-table-th-text: #11062f;
    --color-table-td-border: #9a9a9a;
    --color-table-td-text: #11062f;
}

table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0 0 1em;

    @include mixins.font-size(16px, 24px);
    @include mixins.font(PKFGlobalSans, reg);

    &.no-border {
        td,
        th {
            border-left: 0;
            border-right: 0;
        }
    }

    .email-col {
        word-break: break-all;
    }
}

th,
td {
    padding: var(--table-padding-v) var(--table-padding-h);
}

td {
    border-bottom: var(--table-border-width) solid var(--color-table-td-border);
    color: var(--color-table-td-text);

    a.no-link-style {
        text-decoration: none;
        color: inherit;
    }
}

th {
    background: var(--color-table-th-bg);
    color: var(--color-table-th-text);
    text-align: left;
    min-width: 100px;
    white-space: nowrap;

    &.small-col {
        width: 100px;
    }

    a {
        white-space: nowrap;
        color: var(--color-table-th-text);
        font-weight: 700;
    }
}

.table--striped {
    tr:nth-child(even) {
        background: var(--color-table-tr-even-bg);
    }

    tr:nth-child(odd) {
        background: var(--color-table-tr-odd-bg);
    }
}

.table-layout-auto {
    table-layout: auto;

    td {
        vertical-align: top;
    }
}
