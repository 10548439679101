/*
==========================================================================
   #Error Page
========================================================================== */

.template-error {
    .page-head {
        margin-bottom: 100px;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .logo {
            width: 300px;
            max-width: 100%;
        }
    }
}
