
@use "../tools/mixins";

.org-portal-page {
    .number-row {
        display: flex;
        gap: 10px;

        @include mixins.breakpoint(mobile) {
            flex-direction: column;
        }
    }
}
