@use "../tools/mixins";

.search-form {
    --color-advanced-btn-bg-hover: #9a9a9a;

    @include mixins.breakpoint(not-desktop) {
        display: flex;
        flex-direction: column;
        align-items:stretch;
        gap: 24px;
    }

    &.align-center {
        @include mixins.breakpoint(not-desktop) {
            align-items: stretch;
        }
    }

    &__search {
        float: right;
        display: flex;
        margin-top: 6px;
        margin-bottom: 22px;

        @include mixins.breakpoint(not-desktop) {
            margin-bottom: 0;
        }

        @include mixins.breakpoint(mobile) {
            flex-direction: column;
            gap: 10px;
            float: none;
        }

        .field-wrapper {
            width: 342px;
            max-width: 100%;

            @include mixins.breakpoint(mobile) {
                width: 100%;
            }
        }

        .fieldInputWrapper {
            margin-right: 20px;

            @include mixins.breakpoint(mobile) {
                margin-right: 0;
            }
        }

        .btn {
            align-self: center;

            @include mixins.breakpoint(mobile) {
                width: 100%;
            }
        }
    }

    .show-advanced-search {
        background: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        padding: 7px 12px;
        line-height: 13px;
        outline: 0;

        @include mixins.hover-prop {
            background-color: var(--color-advanced-btn-bg-hover);
        }
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        clear: both;
        margin-bottom: 30px;

        legend {
            margin-right: 14px;
        }
    }

    &__filter {
        display: flex;
        align-items: center;
        margin-left: auto;

        @include mixins.breakpoint(mobile) {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
            margin-top: 20px;
            width: 100%;
         }

        .select-floating-label {
            max-width: 200px;

            @include mixins.breakpoint(mobile) {
                max-width: none;
                width: 100%;
            }
        }
        select {
            @include mixins.breakpoint(mobile) {
                width: 100%;
            }
        }
    }

    &__radios {
        display: flex;
        margin-top: 22px;

        @include mixins.breakpoint(mobile) {
           flex-direction: column;
           margin-top: 0;
        }
    }

    .margin-right {
        margin-right: 20px;
    }
}

.search-results-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    margin-bottom: 15px;
    margin-top: 20px;
    clear: both;
    float: left;
    width: 100%;

    &__label {
        display: inline-flex;
        margin-right: 10px;
        margin-top: 0;
        margin-bottom: 0;
    }

    &__detail {
        display: inline-flex;
        margin-right: 18px;
        margin-top: 0;
        margin-bottom: 0;

        @include mixins.font-size(20px, 28px);

        &.border-right {
            border-right: 2px solid var(--color-2);
            padding-right: 18px;
        }
    }
}

.search-form-empty {
    &__container {
        display: flex;
        justify-content: center;
        margin-top: 80px;
        width: 736px;
        margin-right: auto;
        margin-left: auto;
    }

    .fieldInputWrapper {
        width: 621px;
        margin-right: 20px;
    }
}

.search-pagination {
    display: flex;
    margin-left: auto;
    padding-left: 0;
    list-style: none;
    margin-bottom: 10px;

    .arrow {
        padding: 8px;
    }

    .next {
        margin-left: 11px;
    }

    .prev {
        margin-right: 11px;
    }
}

.no-search-result-message {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 25px;
    padding: 26px 6px 29px 15px;
    clear: both;
    float: left;

    @include mixins.breakpoint(mobile) {
       flex-direction: column;
    }

    .h5 {
        margin-bottom: 10px;
    }

    &.text-center {
        text-align: center;
        align-items: center;
        float: none;
        align-content: center;
        justify-content: center;
    }
}

.search-results-info {
    padding: 28px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        margin-left: auto;
    }
}
