.select2-container {
    .select2-selection {
        padding: 12px var(--form-element-padding-h) 10px;
        border-radius: 0 4px;
        height: 48px;
        padding-right: var(--select-padding-right);
        background-color: var(--color-form-element-bg);
        background-image: url("../icons/dropdown-arrow-2.svg"),
            url("../icons/dropdown-arrow-box.svg");
        background-position: right 15px center, right -1px center;
        background-repeat: no-repeat;

        &__arrow {
            b {
                display: none;
            }
        }
    }

    .select2-selection__choice {
        opacity: 0;
    }
}
