.message-board__filters {
    --item-bg-active: #11062f;
    --item-color-active: #ffffff;
    --item-bg-hover: #d6edff;
    --item-color-hover: #043394;
    .filter-btn {
        &.active {
            background-color: var(--item-bg-active);
            color: var(--item-color-active);
        }

        &:hover {
            background-color: var(--item-bg-hover);
            color: var(--item-color-hover);
        }
    }
}