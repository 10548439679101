@use "../tools/mixins";
.report-search-container {
    margin-bottom: 40px;
    padding: 25px 30px 20px;

    select, input{
        width: 250px;

        @include mixins.breakpoint(mobile) {
            width: 100% !important;
        }
    }

    .report-search-fields {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;
    }

    .form-group {
        @include mixins.breakpoint(mobile) {
            width: 100%;
        }

        label {
            display: block;
        }
    }

    .select2 {
        @include mixins.breakpoint(mobile) {
            width: 100% !important;
        }
    }
}