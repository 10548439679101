/*
==========================================================================
   #Layout
========================================================================== */
.dashboard__outer {
    padding: 20px 20px 20px calc(var(--sidebar-width) + 15px);
}

.dashboard {
    padding-bottom: 40px;
}
