/*
==========================================================================
   #Default
========================================================================== */
// #High-level base settings
:root {
    --base-font-size: 16px;
    --base-line-height: 24px;
    --base-text-color: #052232;
    --base-background-color: #ffffff;
}
